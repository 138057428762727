import { atom } from "recoil";

export const categoriesAtom = atom({
  key: "categories",
  default: [],
});

export const saleGroupsAtom = atom({
  key: "saleGroups",
  default: [],
});
