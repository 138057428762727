import React, { useEffect, useState } from "react";
import { Button, Form, Alert, Container, Row, Col, Navbar, Spinner } from "react-bootstrap";
import { RecoilRoot, useRecoilValue } from "recoil";
import DatePicker from "react-date-picker";
import Categories from "./Categories";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-widgets/styles.css";
import "./App.css";
import { categoriesAtom, saleGroupsAtom } from "./atoms";

const timeToString = (d) => {
  return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
};

// prev. min day was set to 1627776000000 (Sunday, August 1, 2021 0:00:00)
const MIN_DATE = 1638316800000; // Wednesday, December 1, 2021 0:00:00

const MyForm = () => {
  const [alertText, alertTextChanger] = useState("Выберите период");
  const [buttonIsDisabled, buttonIsDisabledChanger] = useState(true);
  const [spinnerActive, spinnerActiveChanger] = useState(false);
  const [showAlert, showAlertChanger] = useState(true);
  const [periodStart, periodStartChange] = useState(null);
  const [periodEnd, periodEndChange] = useState(null);
  const [password, passwordChange] = useState(
    localStorage.getItem("password") ? localStorage.getItem("password") : ""
  );
  const categories = useRecoilValue(categoriesAtom);
  const saleGroups = useRecoilValue(saleGroupsAtom);
  const onButtonSubmit = (e) => {
    e.preventDefault();
    let url;
    if (window.location.port === "3000") {
      url = "http://localhost:8080/get-report";
    } else {
      url = "/get-report";
    }
    const requestBody = {
      periodStart: periodStart.getTime() + 43200000,
      periodEnd: periodEnd.getTime() + 43200000,
      password: password,
      categories: categories.filter((item) => item.active),
      saleGroups: saleGroups.filter((item) => item.active),
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    };
    console.log(requestBody);
    spinnerActiveChanger(true);
    buttonIsDisabledChanger(true);
    fetch(url, requestOptions)
      .then((response) => {
        if (response.status === 403) {
          spinnerActiveChanger(false);
          buttonIsDisabledChanger(true);
          showAlertChanger(true);
          alertTextChanger("Неверный пароль!");
          throw "Wrong password";
        }
        if (response.status === 405) {
          spinnerActiveChanger(false);
          buttonIsDisabledChanger(true);
          showAlertChanger(true);
          alertTextChanger("Неверный метод запроса! Сообщите разработчику.");
          throw "Method not allowed!?";
        }
        if (response.status === 400) {
          spinnerActiveChanger(false);
          buttonIsDisabledChanger(true);
          showAlertChanger(true);
          alertTextChanger("Ошибка запроса! Сообщите разработчику.");
          throw "Bad request!?";
        }
        if (!response.ok) {
          spinnerActiveChanger(false);
          buttonIsDisabledChanger(true);
          showAlertChanger(true);
          alertTextChanger(`Неизвестная ошибка! ${response.text()}`);
          throw "Unknown error";
        }
        return response.blob();
      })
      .then(
        (blob) => {
          const fileName = `Недоступность с ${timeToString(periodStart)} по ${timeToString(
            periodEnd
          )}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          spinnerActiveChanger(false);
          buttonIsDisabledChanger(false);
        },
        (error) => {
          console.log(`Error: ${error}`);
        }
      );
    return false;
  };

  const onPasswordChange = (e) => {
    const currentPassword = e.target.value;
    localStorage.setItem("password", currentPassword);
    passwordChange(currentPassword);
  };

  useEffect(() => {
    if (password.length < 8) {
      alertTextChanger("Введите пароль.");
      showAlertChanger(true);
      buttonIsDisabledChanger(true);
      return;
    }
    var datesSet = false;
    var datesCorrect = false;
    if (periodStart != null && periodEnd != null) {
      datesSet = true;
    } else {
      alertTextChanger("Выберите период.");
      showAlertChanger(true);
      buttonIsDisabledChanger(true);
      return;
    }
    if (periodStart < periodEnd) {
      datesCorrect = true;
    } else {
      alertTextChanger("ОШИБКА! Дата начала периода должна предшествовать дате конца периода.");
      showAlertChanger(true);
      buttonIsDisabledChanger(true);
      return;
    }
    if (categories.filter((item) => item.active).length === 0) {
      alertTextChanger("Выберите хотябы одну категорию");
      showAlertChanger(true);
      buttonIsDisabledChanger(true);
      return;
    }
    if (saleGroups.filter((item) => item.active).length === 0) {
      alertTextChanger("Выберите хотябы одну группу продаж");
      showAlertChanger(true);
      buttonIsDisabledChanger(true);
      return;
    }
    if (datesSet && datesCorrect) {
      showAlertChanger(false);
      buttonIsDisabledChanger(false);
    }
  }, [periodStart, periodEnd, password, categories, saleGroups]);

  return (
    <Form>
      <Row>
        <Form.Group className='mb-3' controlId='periodStart'>
          <Form.Label>Пароль</Form.Label>
          <Form.Control
            type='password'
            onChange={onPasswordChange}
            value={password}
            placeholder='Пароль'
          />
        </Form.Group>
      </Row>
      <Row>
        <Col>
          <Form.Group className='mb-3' controlId='periodStart'>
            <Form.Label>Начало периода</Form.Label>
            <DatePicker
              onChange={periodStartChange}
              value={periodStart}
              format='dd-MM-y'
              minDate={new Date(MIN_DATE)}
              // maxDate={new Date(1735689600000)}
              maxDate={new Date()}
              minDetail='year'
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='mb-3' controlId='periodEnd'>
            <Form.Label>Конец периода</Form.Label>
            <DatePicker
              onChange={periodEndChange}
              value={periodEnd}
              format='dd-MM-y'
              minDate={new Date(MIN_DATE)}
              // maxDate={new Date(1735689600000)}
              maxDate={new Date()}
              minDetail='year'
            />
          </Form.Group>
        </Col>
      </Row>

      <Categories />

      {showAlert ? <Alert className='alert-danger'>{alertText}</Alert> : ""}
      <Button variant='primary' type='submit' onClick={onButtonSubmit} disabled={buttonIsDisabled}>
        Сгенерировать отчёт
      </Button>
      {spinnerActive ? (
        <Spinner
          variant='primary'
          className='ms-3'
          as='span'
          animation='border'
          size='sm'
          role='status'
          aria-hidden='true'
        />
      ) : (
        ""
      )}
    </Form>
  );
};

const App = () => (
  <RecoilRoot>
    <Navbar>
      <Container>
        <Navbar.Brand href='/'>Генератор отчётов недоступности товаров</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className='justify-content-end'></Navbar.Collapse>
      </Container>
    </Navbar>

    <Container className='p-5 mb-4 bg-light rounded-3'>
      <MyForm />
    </Container>
  </RecoilRoot>
);

export default App;
