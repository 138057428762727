import { React, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { categoriesAtom, saleGroupsAtom } from "./atoms";

const fetchCatsAndGroups = async () => {
  let url;
  if (window.location.port === "3000") {
    url = "http://localhost:8080/categories";
  } else {
    url = "/categories";
  }
  const r = await fetch(url);
  const data = await r.json();
  const categories = [];
  const saleGroups = [];
  data["categories"].forEach((category, index) => {
    if (category.endsWith("/")) {
      category = category.substring(0, category.length - 1);
    }
    categories.push({
      name: category,
      index: `cat-${index}`,
      active: true,
      id: index,
    });
  });
  data["sale_groups"].forEach((saleGroup, index) => {
    saleGroups.push({
      name: saleGroup,
      index: `sg-${index}`,
      active: true,
      id: index,
    });
  });
  return {
    categories: categories,
    saleGroups: saleGroups,
  };
};

const saveToLocalStorage = (data) => {
  localStorage.setItem("filters", JSON.stringify(data));
};

const getFromLocalStorage = () => {
  if (!("filters" in localStorage)) {
    return false;
  }
  return JSON.parse(localStorage.getItem("filters"));
};

const Categories = () => {
  const [categories, setCategories] = useRecoilState(categoriesAtom);
  const [saleGroups, setSaleGroups] = useRecoilState(saleGroupsAtom);

  useEffect(() => {
    const data = getFromLocalStorage();
    if (data) {
      setCategories(data["categories"]);
      setSaleGroups(data["saleGroups"]);
    } else {
      fetchCatsAndGroups().then((data) => {
        if (!("categories" in data) || !("saleGroups" in data)) return;
        setCategories(data["categories"]);
        setSaleGroups(data["saleGroups"]);
        saveToLocalStorage(data);
      });
    }
  }, []);

  const onCategoryCheckBoxChange = (id) => {
    let newCategories = categories.map((item) => {
      return { active: item.active, id: item.id, index: item.index, name: item.name };
    });
    newCategories[id]["active"] = !newCategories[id]["active"];
    setCategories(newCategories);
    saveToLocalStorage({ categories: newCategories, saleGroups: saleGroups });
  };

  const onSaleGroupCheckBoxChange = (id) => {
    let newSaleGroups = saleGroups.map((item) => {
      return { active: item.active, id: item.id, index: item.index, name: item.name };
    });
    newSaleGroups[id]["active"] = !newSaleGroups[id]["active"];
    setSaleGroups(newSaleGroups);
    saveToLocalStorage({ categories: categories, saleGroups: newSaleGroups });
  };

  const createCategoryCheckBoxes = () => {
    return categories.map(({ name, index, active, id }) => {
      return (
        <span className='me-3 noselect' style={{ whiteSpace: "nowrap" }} key={index}>
          <input
            type='checkbox'
            id={index}
            checked={active}
            onChange={() => onCategoryCheckBoxChange(id)}
          />
          <label htmlFor={index} className='ms-1'>
            {name}
          </label>
        </span>
      );
    });
  };
  const createSaleGroupCheckBoxes = () => {
    return saleGroups.map(({ name, index, active, id }) => {
      return (
        <span className='me-3' style={{ whiteSpace: "nowrap" }} key={index}>
          <input
            type='checkbox'
            id={index}
            checked={active}
            onChange={() => onSaleGroupCheckBoxChange(id)}
          />
          <label htmlFor={index} className='ms-1 noselect'>
            {name}
          </label>
        </span>
      );
    });
  };
  if (categories.length === 0) {
    return <ProgressBar animated now={100} />;
  } else {
    return (
      <div className='mb-3'>
        <h6>Категории</h6>
        {createCategoryCheckBoxes()}
        <h6>Группы продаж</h6>
        {createSaleGroupCheckBoxes()}
      </div>
    );
  }
};

export default Categories;
